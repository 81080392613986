import { StyleFunctionProps, defineStyleConfig, defineStyle } from '@chakra-ui/styled-system'
import { mode } from '@chakra-ui/theme-tools'
import { switchTheme } from './switch'
import { menuTheme } from './menu'
import { bg } from 'date-fns/locale'
import { over } from 'lodash'

const components: unknown = {
  Switch: switchTheme,
  Menu: menuTheme,
  Skeleton: {
    baseStyle: {},
    defaultProps: {
      startColor: 'textSoft',
      endColor: 'textAsh',
    },
  },
  Button: {
    defaultProps: {
      fontFamily: 'Inter',
      colorScheme: 'tipbox.primary',
      borderRadius: 8,
      _hover: {
        bg: 'tipbox.primary.700',
        _disabled: {
          bg: 'tipbox.primary.500',
        },
      },
      _active: { bg: 'tipbox.primary.800' },
    },
    variants: {
      defaultButton: (props: StyleFunctionProps) => ({
        w: 'auto',
        borderRadius: 8,
        fontSize: [12, null, 16],
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'accent',
        fontFamily: 'Inter',
        color: 'white',
        lineHeight: 'auto',
        fill: 'white',
        _focusVisible: { boxShadow: '0 0 0 1px borderFocus', borderColor: 'white' },
        _hover: {
          bg: 'accentHighlight',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
        _active: { bg: 'accent' },
        _disabled: {
          bg: 'purpleDisabled',
          color: 'neutralGrey',
        },
      }),
      defaultGreyDisabled: (props: StyleFunctionProps) => ({
        w: 'auto',
        borderRadius: 8,
        fontSize: [12, null, 16],
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'accent',
        fontFamily: 'Inter',
        color: 'white',
        border: '1px',
        borderColor: 'accent',
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'accent',
          borderColor: 'tipbox.primary.700',
          _disabled: {
            borderColor: 'textSoft',
            bgColor: 'textSoft',
            color: mode('textSoft', 'tipbox.dark.900')(props),
          },
        },
        _active: { bg: 'tipbox.primary.800' },
        _disabled: {
          borderColor: 'textSoft',
          bgColor: 'textSoft',
          color: mode('textSoft', 'tipbox.dark.900')(props),
        },
      }),
      defaultButtonSm: (props: StyleFunctionProps) => ({
        h: '2rem',
        borderRadius: 5,
        fontSize: [12, null, 14],
        px: 4,
        py: 2,
        bg: mode('tipbox.primary.600', 'accent')(props),
        fontFamily: 'Inter',
        color: 'white',
        border: '1px',
        borderColor: mode('tipbox.primary.600', 'accent')(props),
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: mode('tipbox.primary.700', 'accentHighlight'),
          borderColor: mode('tipbox.primary.700', 'accentHighlight'),
          _disabled: {
            bgColor: mode('tipbox.primary.600', 'accent')(props),
          },
        },
        _active: { bg: 'tipbox.primary.800' },
      }),
      disabled: {
        w: 'auto',
        borderRadius: 8,
        fontSize: [12, null, 16],
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'textSoft',
        fontFamily: 'Inter',
        color: 'textRegular',
        lineHeight: 'auto',
      },
      urgent: (props: StyleFunctionProps) => ({
        w: 'auto',
        borderRadius: 8,
        fontSize: [12, null, 16],
        px: 5,
        py: 1,
        h: 'auto',
        bg: mode('tipbox.primary.700', 'alert')(props),
        fontFamily: 'Inter',
        fontWeight: 500,
        color: 'white',
        border: '1px',
        borderColor: mode('tipbox.primary.600', 'accent')(props),
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: mode('tipbox.primary.700', 'accentHighlight'),
          borderColor: 'tipbox.primary.700',
          _disabled: {
            bgColor: mode('tipbox.primary.600', 'accent')(props),
          },
        },
        _active: { bg: 'tipbox.primary.800' },
      }),
      successButton: {
        w: 'auto',
        borderRadius: 8,
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'success',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        fontWeight: 500,
        color: 'white',
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'successAccent',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
      },
      approve: ({ colorMode }: StyleFunctionProps) => ({
        w: 'auto',
        borderRadius: 8,
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'success',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        fontWeight: 500,
        color: 'white',
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'successAccent',
          _disabled: {
            bgColor: 'transparent',
          },
        },
      }),
      reject: ({ colorMode }: StyleFunctionProps) => ({
        bg: 'transparent',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        color: 'textHighlight',
        w: 'auto',
        h: 'auto',
        px: 8,
        py: 2,
        border: '1px',
        lineHeight: 'auto',
        borderColor: 'textHighlight',
        borderRadius: 8,
        _focus: { boxShadow: 'none' },
        _active: { bg: 'reject' },
        _hover: {
          bg: 'reject',
          borderColor: 'reject',
          _disabled: {
            borderColor: 'textRegular',
            bgColor: 'textRegular',
            color: colorMode === 'dark' ? 'tipbox.dark.900' : 'textSoft',
          },
        },
        _disabled: {
          borderColor: 'textRegular',
          bgColor: 'textRegular',
          color: colorMode === 'dark' ? 'tipbox.dark.900' : 'textSoft',
        },
      }),
      pendingButton: {
        w: 'auto',
        borderRadius: 8,
        px: 8,
        py: 2,
        h: 'auto',
        bg: 'accent',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        fontWeight: 500,
        color: 'white',
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'accentHighlight',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
      },
      ghost: (props: StyleFunctionProps) => ({
        color: 'textBlack',
        _hover: {
          bg: 'transparent',
          // _disabled: {
          //   bgColor: 'tipbox.yellow.500',
          // },
        },
      }),
      btnOutline: ({ colorMode }: StyleFunctionProps) => ({
        bg: 'transparent',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        w: 'auto',
        h: 'auto',
        px: 8,
        py: 2,
        border: '1px',
        lineHeight: 'auto',
        borderColor: 'textSoft',
        borderRadius: 8,
        _focusVisible: { boxShadow: '0 0 0 1px borderFocus', border: '1px solid transpaent' },
        _active: { bg: 'accentHighlight' },
        _hover: {
          borderColor: 'transparent',
          bg: 'accent',
          color: 'textHighlight',
        },
      }),
      btnOAuth: ({ colorMode }: StyleFunctionProps) => ({
        bg: 'white',
        fontSize: [12, null, 16],
        fontFamily: 'Inter',
        w: 'auto',
        h: 'auto',
        px: 8,
        py: 2,
        border: '1px',
        lineHeight: 'auto',
        borderColor: 'textSoft',
        borderRadius: 8,
        color: 'tipbox.dark.text.darkGrey',
        _focusVisible: { boxShadow: '0 0 0 1px borderFocus', border: '1px solid transparent' },
        _active: { bg: 'accentHighlight' },
        _hover: {
          borderColor: 'textSoft',
          bg: 'accent',
          color: 'textHighlight',
          fill: 'textHighlight',
        },
      }),
      btnOutlineSm: (props: StyleFunctionProps) => ({
        bg: 'white',
        fontSize: [12, null, 14],
        fontFamily: 'Inter',
        color: mode('tipbox.primary.600', 'accent')(props),
        h: '2rem',
        px: 4,
        py: 2,
        border: '1px',
        lineHeight: 'auto',
        borderColor: mode('tipbox.primary.600', 'accent')(props),
        borderRadius: 8,
        _focus: { boxShadow: 'none' },
        _active: { bg: 'white' },
        _hover: { bg: 'tipbox.primary.100' },
      }),
      opacityButton: {
        bg: 'textBlack',
        fontSize: 12,
        fontWeight: 400,
        px: 2,
        py: 1,
        borderRadius: 8,
        opacity: '80%',
        _focus: { boxShadow: 'none' },
        _active: { bg: 'textRegular' },
        _hover: { bg: 'accent', color: 'textHighlight', opacity: '100%' },
      },
      passwordBtn: (props: StyleFunctionProps) => ({
        w: 'full',
        fontFamily: 'Inter',
        border: 'none',
        bg: 'none',
        color: mode('textBlack', 'textSoft')(props),
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'none', color: 'tipbox.primary.700' },
        _active: { bg: 'none', color: 'tipbox.primary.700' },
      }),
      backBtn: (props: StyleFunctionProps) => ({
        w: 'auto',
        h: '3.125rem',
        p: 0,
        mx: 0,
        bg: 'none',
        fontFamily: 'Inter',
        fontSize: 10,
        borderRadius: '8px',
        border: '2px solid #393952',
        backgroundColor: '#131628',
        color: 'textHighlight',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'accent',
          color: mode('tipbox.primary.600', 'textHighlight')(props),
        },
        _active: { bg: 'none', color: 'textHighlight' },
      }),
      iconBtn: (props: StyleFunctionProps) => ({
        border: 'none',
        w: 'auto',
        p: 0,
        mx: 0,
        h: '3.125rem',
        bg: 'none',
        fontSize: 10,
        color: mode('textRegular', 'textSoft')(props),
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'none',
          color: mode('tipbox.primary.600', 'textHighlight')(props),
        },
        _active: { bg: 'none', color: 'textHighlight' },
      }),
      iconBtnSelected: (props: StyleFunctionProps) => ({
        border: 'none',
        w: 'auto',
        h: '3.125rem',
        p: 0,
        mx: 0,
        bg: 'none',
        fontFamily: 'Inter',
        fontSize: 10,
        color: mode('tipbox.primary.600', 'textHighlight')(props),
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'none',
          color: mode('tipbox.primary.600', 'textHighlight')(props),
        },
        _active: { bg: 'none', color: 'textHighlight' },
      }),
      iconBtnSm: (props: StyleFunctionProps) => ({
        border: 'none',
        w: 'auto',
        p: 0,
        mx: 0,
        h: '0.938rem',
        bg: 'none',
        fontSize: 10,
        color: 'textHighlight',
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'none',
        },
        _active: { bg: 'none', color: 'textSoft' },
        minW: 'auto',
      }),
      iconBtnLtGrey: (props: StyleFunctionProps) => ({
        border: '1px solid',
        w: 'auto',
        p: 0,
        mx: 0,
        bg: 'none',
        fontSize: 10,
        color: 'textBlack',
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'accent',
          color: 'textHighlight',
        },
        _active: { bg: 'none', color: 'tipbox.primary.700' },
      }),
      iconBtnRed: (props: StyleFunctionProps) => ({
        border: 'none',
        w: 'auto',
        p: 0,
        mx: 0,
        h: '3.25rem',
        bg: 'none',
        fontSize: 10,
        color: 'accent',
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'none', color: 'textRegular' },
        _active: { bg: 'none', color: 'textSoft' },
      }),
      iconBtnRedLarge: (props: StyleFunctionProps) => ({
        border: 'none',
        w: ['100%', null, null, 'auto'],
        p: 0,
        mx: 0,
        h: '3.25rem',
        bg: 'none',
        fontSize: 16,
        color: mode('tipbox.primary.600', 'accent')(props),
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'none', color: 'tipbox.primary.700' },
        _active: { bg: 'none', color: 'tipbox.primary.800' },
      }),
      quickstartButton: (props: StyleFunctionProps) => ({
        fontSize: [12, null, '1rem'],
        borderRadius: 0,
        px: [4, null, 6],
        h: 'auto',
        py: 3,
        bg: 'transparent',
        color: 'textRegular',
        fontFamily: 'Inter',
        fontWeight: 500,
        // flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none', color: 'white' },
      }),
      dashboardUpdate: {
        w: ['15vw', '6rem', '7vw'],
        h: ['2.75rem', '2.25rem', '2.75rem'],
        bg: 'background',
        borderRadius: 6,
        color: 'textRegular',
        _hover: {
          bg: 'accent',
          color: 'textHighlight',
          cursor: 'pointer',
        },
      },
      navMenuButton: {
        fontSize: '15px',
        borderRadius: 0,
        fontFamily: 'Inter',
        h: 'auto',
        pl: '18px',
        bg: 'transparent',
        color: 'textRegular',
        fontWeight: 400,
        justifyContent: 'flex-start',
        w: '100%',
        _focus: { boxShadow: 'none', color: 'textHighlight' },
        _hover: {
          color: 'textHighlight',
          fontWeight: 500,
        },
        _active: { color: 'textHighlight' },
      },
      navMenuButtonSelect: {
        fontSize: '15px',
        borderRadius: 0,
        fontFamily: 'Inter',
        h: 'auto',
        pl: '18px',
        bg: 'transparent',
        color: 'textHighlight',
        fontWeight: 500,
        w: '100%',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _active: { color: 'textHighlight' },
      },
      listButton: (props: StyleFunctionProps) => ({
        fontSize: [12, null, 16],
        borderRadius: 0,
        px: [4, null, 6],
        h: 'auto',
        py: 2,
        bg: 'transparent',
        color: 'textRegular',
        fontFamily: 'Inter',
        fontWeight: 500,
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none', color: 'textHighlight' },
        _hover: {
          color: 'textHighlight',
        },
        _active: { color: 'white' },
      }),
      listButtonSelect: (props: StyleFunctionProps) => ({
        fontSize: [12, null, 16],
        borderRadius: 0,
        fontFamily: 'Inter',
        px: [4, null, 6],
        h: 'auto',
        py: 2,
        bg: 'transparent',
        color: 'white',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: {
          color: 'textHighlight',
        },
        _active: { color: 'textHighlight' },
      }),
      dropDownButton: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        color: 'textBlack',
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 0,
        px: [4, null, 8],
        h: '38px',
        w: 'full',
        py: 2,
        bg: 'transparent',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'menuBackground',
          color: 'textHighlight',
          fill: 'textHighlight',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
        _active: {
          bg: 'menuBackground',
          color: 'textHighlight',
        },
      }),
      dropdownOptionButton: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        color: 'textBlack',
        fontSize: 14,
        borderRadius: 0,
        h: 'auto',
        w: 'full',
        py: '10px',
        fontWeight: 500,
        bg: 'transparent',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none', bg: 'menuBackground', color: 'textHighlight' },
        _hover: {
          bg: 'menuBackground',
          color: 'textHighlight',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
        _active: {
          bg: 'menuBackground',
          color: 'textRegular',
        },
      }),
      sendCommentBtn: {
        marginRight: '5px ',
        borderRadius: '50%',
        minWidth: '30px',
        height: '30px',
        padding: 0,
        margin: 0,
        _hover: { background: '#e6e6e6 !important' },
      },
      dropDownSearchButton: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        color: mode('textBlack', 'textSoft')(props),
        fontSize: [14, null, 16],
        borderRadius: 0,
        px: [4, null, 4],
        h: 'auto',
        w: 'full',
        py: 2,
        bg: 'transparent',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'tipbox.primary.700', color: 'white' },
        _active: {
          bg: mode('tipbox.primary.600', 'accent')(props),
          color: 'white',
        },
      }),
      linkButton: (props: StyleFunctionProps) => ({
        color: 'accent',
        w: [null, null, null, 'full'],
        fontSize: [12, null, 16],
        fontWeight: 700,
        pl: 1,
        border: 'none',
        bg: 'transparent',
        _active: { bg: 'transparent', color: 'accentHighlight' },
        _hover: { bg: 'transparent', textDecoration: 'underline' },
        _focus: { boxShadow: 'none' },
      }),
      inlineLinkButton: (props: StyleFunctionProps) => ({
        color: 'accent',
        w: 'auto',
        fontSize: 14,
        fontWeight: 700,
        pl: 1,
        pr: 1,
        pb: 1,
        border: 'none',
        bg: 'transparent',
        _active: { bg: 'transparent', color: 'accentHighlight' },
        _hover: { bg: 'transparent', textDecoration: 'underline' },
        _focus: { boxShadow: 'none' },
      }),
      modalBtn: (props: StyleFunctionProps) => ({
        w: ['full', null, null, 'auto'],
        borderRadius: 8,
        fontSize: [12, null, null, 16],
        px: 8,
        py: 2,
        mt: 1.5,
        mb: 3,
        ml: 4,
        bg: 'accent',
        fontFamily: 'Inter',
        color: 'textHighlight',
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'accentHighlight',
          _disabled: {
            bgColor: 'textRegular',
          },
        },
        _active: { bg: 'tipbox.primary.800' },
      }),
      modalbtnOutline: (props: StyleFunctionProps) => ({
        bg: mode('white', 'transparent')(props),
        color: mode('tipbox.primary.600', 'white')(props),
        fontSize: [12, null, null, 16],
        w: ['full', null, null, 'auto'],
        mt: 1.5,
        mb: 3,
        px: 8,
        border: '1px',
        borderColor: mode('tipbox.primary.600', 'white')(props),
        borderRadius: 8,
        _active: { bg: 'white' },
        _hover: {
          bg: mode('tipbox.primary.100', 'accent')(props),
          borderColor: mode('', 'accent')(props),
        },
      }),
      filterbtnOutline: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        bg: mode('white', 'textBlack')(props),
        color: mode('textBlack', 'textSoft')(props),
        justify: 'space-between',
        algin: 'center',
        textAlign: 'left',
        fontSize: [12],
        flexWrap: 'nowrap',
        mt: 1.5,
        mb: 3,
        px: 2,
        ml: [4, null, null, 0],
        mr: 0,
        h: 8,
        border: '1px',
        borderColor: 'textAsh',
        borderRadius: 8,
        overflowX: 'hidden',
        _active: { bg: 'white' },
        _hover: { bg: 'textSoft' },
      }),
      selectTriggerBtn: (props: StyleFunctionProps) => ({
        fontSize: 12,
        fontFamily: 'Inter',
        color: mode('textBlack', 'textSoft')(props),
        bg: 'transparent',
        border: 'none',
        px: 0,
        _focus: { boxShadow: 'none' },
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent' },
      }),
      selectTriggerBtnGrey: {
        fontSize: 12,
        fontFamily: 'Inter',
        color: 'textAsh',
        bg: 'transparent',
        fontweight: 'normal',
        border: 'none',
        px: 0,
        _focus: { boxShadow: 'none' },
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent' },
      },
      selectTriggeroptions: (props: StyleFunctionProps) => ({
        w: 'full',
        minW: '100px',
        fontSize: 15.7,
        textAlign: 'center',
        justifyContent: 'flex-start',
        fontFamily: 'Inter',
        color: 'textBlack',
        fontWeight: 'bold',
        bg: 'transparent',
        borderRadius: 0,
        border: 'none',
        py: 2,
        px: 4,
        textTransform: 'capitalize',
        _focus: { boxShadow: 'none' },
        _active: { bg: 'menuBackground', color: 'textHighlight' },
        _hover: { bg: 'menuBackground', color: 'textHighlight' },
      }),
      dashedBtn: {
        border: '1px',
        borderColor: 'textAsh',
        borderStyle: 'dashed',
        color: 'textAsh',
        rounded: 8,
      },
      toastButton: {
        bg: 'transparent',
        fontFamily: 'Inter',
        fontSize: 14,
        border: 'none',
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent', color: 'textSoft' },
        _focus: { boxShadow: 'none' },
      },
      breadcrumbBtn: (props: StyleFunctionProps) => ({
        w: 'full',
        h: 'auto',
        minW: '100px',
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Inter',
        color: 'textBlack',
        fontWeigth: 'normal',
        justifyContent: 'space-between',
        bg: 'textHighlight',
        border: 'none',
        rounded: 0,
        px: 6,
        textTransform: 'capitalize',
        _focus: { boxShadow: 'none' },
        _active: { bg: 'transparent' },
        _hover: { bg: 'menuBackground', color: 'textHighlight' },
      }),
      carouselBtn: (props: StyleFunctionProps) => ({
        color: mode('tipbox.primary.600', 'accent')(props),
        bg: 'white',
        w: '25px',
        minW: 'auto',
        h: '25px',
        px: 0,
        m: 0,
        _focus: { boxShadow: 'none' },
        _hover: { border: '1px' },
      }),
      projectsCarouselBtn: () => ({
        color: 'textRegular',
        bg: 'menuBackground',
        w: '35px',
        minW: 'auto',
        h: '35px',
        px: 0,
        m: 0,
        _focus: { boxShadow: 'none' },
        _hover: { border: '1px' },
      }),
      inputPopoverBtn: (props: StyleFunctionProps) => ({
        fontSize: 16,
        fontFamily: 'Inter',
        color: mode('tipbox.primary.600', 'accent')(props),
        fontWeight: 'nornal',
        bg: 'transparent',
        textTransform: 'capitalize',
        border: 'none',
        _focus: { boxShadow: 'none' },
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent' },
      }),
      popoverBtn: (props: StyleFunctionProps) => ({
        fontSize: 16,
        w: ['full', null, null, '164px'],
        h: '40px',
        fontFamily: 'Inter',
        color: mode('textBlack', 'textSoft')(props),
        fontWeight: 'nornal',
        bg: 'transparent',
        justifyContent: 'space-between',
        border: '1px',
        borderColor: 'accent',
        _focus: { boxShadow: 'none' },
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent' },
      }),
      filterButtons: (props: StyleFunctionProps) => ({
        w: ['full', null, null, 'auto'],
        borderRadius: 8,
        fontSize: [12, null, 16],
        px: 8,
        py: 2,
        h: 'auto',
        bg: mode('tipbox.primary.600', 'accent')(props),
        fontFamily: 'Inter',
        color: 'white',
        border: '1px',
        borderColor: mode('tipbox.primary.600', 'accent')(props),
        lineHeight: 'auto',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'tipbox.primary.700' },
        _active: { bg: 'tipbox.primary.800' },
      }),
      fileTreeBtn: (props: StyleFunctionProps) => ({
        fontSize: 14,
        w: 'full',
        borderRadius: 0,
        pr: 4,
        pl: 2,
        h: 'auto',
        py: 2,
        bg: 'transparent',
        color: mode('textBlack', 'textSoft')(props),
        fontWeight: 'normal',
        fontFamily: 'Inter',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'transparent', TextDecoder: 'under-line' },
      }),
      fileTreeSeletedBtn: (props: StyleFunctionProps) => ({
        fontSize: 14,
        w: 'full',
        borderRadius: 4,
        pr: 4,
        pl: 2,
        h: 'auto',
        py: 2,
        bg: mode('tipbox.primary.600', 'accent')(props),
        color: 'white',
        fontWeight: 'normal',
        fontFamily: 'Inter',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: { bg: 'tipbox.primary.700', TextDecoder: 'under-line' },
      }),
      shareBtn: (props: StyleFunctionProps) => ({
        fontSize: 14,
        w: 'full',
        borderRadius: 0,
        pr: 4,
        pl: 2,
        h: 'auto',
        py: 2,
        bg: 'transparent',
        color: mode('textBlack', 'textSoft')(props),
        fontWeight: 'normal',
        fontFamily: 'Inter',
        justifyContent: 'flex-start',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'tipbox.primary.100',
        },
      }),
      iconBreadcrumbsBtn: (props: StyleFunctionProps) => ({
        border: 'none',
        w: '30px',
        minW: '0',
        h: '30px',
        p: 0,
        mx: 0,
        bg: 'none',
        fontSize: 10,
        color: mode('textRegular', 'textSoft')(props),
        fontFamily: 'Inter',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: 'none',
          color: mode('tipbox.primary.600', 'accent')(props),
        },
        _active: { bg: 'none', color: 'tipbox.primary.700' },
      }),
      transparentButton: () => ({
        backgroundColor: 'transparent',
        _hover: {
          backgroundColor: 'transparent',
        },
        _active: {
          backgroundColor: 'transparent',
        },
      }),
      tipMenuButton: (props: StyleFunctionProps) => ({
        color: mode('white', 'textSoft')(props),
        borderRadius: 0,
        bg: 'transparent',
        justifyContent: 'center',
        _focus: { boxShadow: 'none' },
        _hover: {
          color: 'white',
          _disabled: {
            bgColor: mode('tipbox.primary.600', 'accent')(props),
          },
        },
        _active: {
          color: 'white',
        },
      }),
    },
  },
  Stepper: {
    baseStyle: (props: StyleFunctionProps) => ({
      separator: {
        backgroundColor: '#5C5C5C',
        height: '6px !important',
        borderRadius: '12px',
        _active: {
          backgroundColor: 'accent',
        },
      },
    }),
  },
  Modal: {
    baseStyle: (props: StyleFunctionProps) => ({
      header: {
        color: mode('textBlack', 'white')(props),
      },
      dialog: {
        background: mode('white', 'black')(props),
      },
    }),
    variants: {
      commentModal: {
        dialogContainer: {
          overflow: 'visible',
        },
      },
    },
  },
  Checkbox: {
    baseStyle: (props: StyleFunctionProps) => ({
      icon: {
        color: 'tipbox.dark.border.lightGrey',
      },
      control: {
        border: '1px',
        borderColor: 'tipbox.dark.border.lightGrey',
        _checked: {
          bg: 'tipbox.dark.background.midnight',
          borderColor: 'tipbox.dark.border.lightGrey',
          _hover: {
            bg: 'tipbox.dark.background.midnight',
            borderColor: 'tipbox.dark.border.lightGrey',
          },
        },
      },
    }),
    variants: {
      tipbox: (props: StyleFunctionProps) => ({
        icon: {
          color: 'white',
        },
        control: {
          border: '1px',
          borderColor: 'accent',
          borderRadius: '50%',
          _checked: {
            bg: 'accent',
            borderColor: 'accent',
            _hover: {
              bg: 'accentHighlight',
              borderColor: 'accentHighlight',
            },
            // _disabled: {
            //   borderColor: 'textSoft',
            //   bg: mode('tipbox.disabled', 'textRegular')(props),
            // },
          },
          _disabled: {
            borderColor: 'textSoft',
            bg: mode('tipbox.disabled', 'white')(props),
          },
        },
        label: {
          fontWeight: 'medium',
          color: 'textBlack',
        },
      }),
      auth: (props: StyleFunctionProps) => ({
        icon: {
          color: 'tipbox.dark.border.lightGrey',
        },
        control: {
          border: '1px',
          borderColor: 'tipbox.dark.text.grey',
          backgroundColor: 'tipbox.dark.text.grey',
          borderRadius: '4px',
          _checked: {
            bg: 'accent',
            borderColor: 'accent',
            _hover: {
              bg: 'accentHighlight',
              borderColor: 'accentHighlight',
            },
          },
        },
      }),
    },
  },
  Container: {
    variants: {
      badgeFlexAlert: (props: StyleFunctionProps) => ({
        bg: mode('tipbox.primary.100', 'black')(props),
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        mt: 4,
        mb: 4,
        py: 4,
      }),
      badgeFlexConfirm: {
        bg: 'accent',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        mt: 4,
        mb: 4,
        py: 4,
      },
      projectCard: (props: StyleFunctionProps) => ({
        p: 0,
        m: 0,
        w: 'full',
        h: 'full',
        display: 'flex',
        flexDir: 'column',
        pos: 'absolute',
        borderRadius: 8,
        bg: 'cardBackground',
        border: '1px',
        borderColor: 'transparent',
        _hover: {
          borderColor: 'textSoft',
          bg: 'cardBackgroundAccent',
        },
      }),
      boxCard: (props: StyleFunctionProps) => ({
        p: 0,
        m: 0,
        h: '100%',
        w: '100%',
        pos: 'absolute',
        display: 'flex',
        flexDir: 'column',
        left: 0,
        border: '1px',
        borderColor: 'transparent',
        rounded: 8,
        bg: 'cardBackground',
        _hover: {
          borderColor: 'textSoft',
          bg: 'cardBackgroundAccent',
        },
      }),
      boxCardMediaBg: (props: StyleFunctionProps) => ({
        p: 0,
        m: 0,
        h: '100%',
        w: '100%',
        pos: 'absolute',
        display: 'flex',
        flexDir: 'column',
        left: 0,
        rounded: 8,
        cursor: 'pointer',
        _hover: {
          borderColor: 'textSoft',
          border: '1px',
        },
      }),
      boxListCard: (props: StyleFunctionProps) => ({
        overFlow: 'hidden',
        w: 'full',
        color: 'textHighlight',
        maxW: 'full',
        h: '64px',
        border: '1px',
        borderColor: 'transparent',
        rounded: 8,
        p: 0,
        m: 0,
        bg: 'cardBackground',
        _hover: {
          borderColor: 'textSoft',
          bg: 'cardBackgroundAccent',
        },
      }),
      dashboardListCard: (props: StyleFunctionProps) => ({
        overFlow: 'hidden',
        bg: mode('white', 'background')(props),
        w: 'full',
        maxW: 'full',
        h: '64px',
        border: '1px',
        borderColor: 'transparent',
        rounded: 8,
        p: 0,
        m: 0,
        _hover: {
          borderColor: mode('tipbox.primary.600', 'accent')(props),
        },
      }),
      tipCard: {
        p: 0,
        m: 0,
        h: '100%',
        w: '100%',
        pos: 'absolute',
        left: 0,
        rounded: 8,
        overflow: 'hidden',
        border: '1px',
        borderColor: 'transparent',
      },
      tipListCard: (props: StyleFunctionProps) => ({
        p: 0,
        m: 0,
        maxW: 'full',
        h: '64px',
        bg: 'cardBackground',
        rounded: 8,
        overflow: 'hidden',
        border: '1px',
        borderColor: 'transparent',
        _hover: {
          borderColor: 'textSoft',
          bg: 'cardBackgroundAccent',
        },
      }),
    },
  },
  Divider: {
    baseStyle: (props: StyleFunctionProps) => ({
      borderColor: 'borderRegular',
    }),
    variants: {
      modal: {
        borderColor: '#E9EAEC',
      },
    },
  },
  Heading: {
    defaultProps: {
      size: '',
    },
    baseStyle: (props: StyleFunctionProps) => ({
      fontFamily: 'Inter',
      color: mode('textBlack', 'white')(props),
    }),
    variants: {
      mobile: {
        textAlign: 'center',
      },
      authHeading: {
        textAlign: 'center',
      },
      desktop: {},
    },
  },
  FormLabel: {
    baseStyle: (props: StyleFunctionProps) => ({
      fontSize: [12, null, null, 16],
      fontWeight: 600,
      color: mode('textBlack', 'white')(props),
    }),
  },
  Text: {
    defaultProps: (props: StyleFunctionProps) => ({
      fontFamily: 'Inter',
      color: mode('textBlack', 'textSoft')(props),
      fontSize: [14, null, null, 16],
    }),
    baseStyle: (props: StyleFunctionProps) => ({
      fontFamily: 'Inter',
      color: mode('textBlack', 'textSoft')(props),
    }),
    variants: {
      errorText: {
        bg: 'transparent',
        color: 'tipbox.primary.800',
      },
      tiny: {
        color: 'textBlack',
        fontSize: [9, null, null, 12],
      },
      tinyHightlight: {
        color: 'textBlack',
        fontSize: [9, null, null, 12],
      },
      tinyHighlightDashboard: {
        color: 'textSoft',
        fontSize: 12,
      },
      tinyCharcoal: {
        color: 'tipbox.dark.text.charcoalGrey',
        fontSize: [9, null, null, 12],
      },
      small: {
        color: 'textBlack',
        fontSize: [11, null, null, 14],
        fontWeight: 400,
        lineHeight: '20px',
      },
      smallRegular: {
        color: 'textRegular',
        fontSize: [11, null, null, 14],
        fontWeight: 400,
        lineHeight: '20px',
      },
      smallDashboard: {
        color: 'textRegular',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
      },
      smallBold: {
        color: 'textBlack',
        fontWeight: 600,
        fontSize: [11, null, null, 14],
        lineHeight: '20px',
      },
      smallCharcoal: {
        color: 'tipbox.dark.text.charcoalGrey',
        fontSize: [11, null, null, 14],
      },
      smallGrey: (props: StyleFunctionProps) => ({
        color: 'cardBackground',
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: [12, null, null, 16],
      }),
      smallHighlight: (props: StyleFunctionProps) => ({
        color: 'textBlack',
        fontSize: [11, null, null, 14],
        fontWeight: 400,
        lineHeight: '20px',
      }),
      smallHighlightBold: (props: StyleFunctionProps) => ({
        color: 'textBlack',
        fontWeight: 600,
        fontSize: [11, null, null, 14],
        lineHeight: '20px',
      }),
      mediumHighlight: (props: StyleFunctionProps) => ({
        color: 'textBlack',
        fontSize: [14, null, null, 16],
        fontWeight: 400,
      }),
      mediumHighlightBold: (props: StyleFunctionProps) => ({
        color: 'textBlack',
        fontWeight: 700,
        fontSize: [14, null, null, 16],
      }),
      large: {
        fontSize: [16, null, null, 18],
      },
      largeHighlight: (props: StyleFunctionProps) => ({
        color: mode('black', 'white')(props),
        fontSize: [16, null, null, 18],
      }),
      largeHighlightBold: (props: StyleFunctionProps) => ({
        color: 'textSoft',
        fontWeight: 700,
        fontSize: [16, null, null, 18],
      }),
      largeDashboard: (props: StyleFunctionProps) => ({
        color: 'textSoft',
        fontWeight: 500,
        fontSize: [16, null, null, 18],
      }),
      xlarge: {
        fontSize: [20, null, null, 24],
      },
      xlargeHighlight: (props: StyleFunctionProps) => ({
        color: 'textSoft',
        fontSize: [20, null, null, 24],
      }),
      xlargeHighlightBold: (props: StyleFunctionProps) => ({
        color: mode('black', 'white')(props),
        fontWeight: 700,
        fontSize: [20, null, null, 24],
      }),
      quote: (props: StyleFunctionProps) => ({
        color: 'textRegular',
        fontSize: [11, null, null, 14],
        fontWeight: 400,
        lineHeight: '20px',
        fontStyle: 'italic',
        whiteSpace: 'break-spaces',
      }),
      formSectionHeading: {
        color: 'textRegular',
        fontSize: [12, null, null, 16],
        fontWeight: 600,
      },
      introductoryTitle: (props: StyleFunctionProps) => ({
        color: mode('black', 'white')(props),
        fontSize: ['18px', null, null, '48px'],
        lineHeight: ['24px', null, null, '50px'],
        fontWeight: 400,
      }),
      introductoryBodyHightlight: (props: StyleFunctionProps) => ({
        color: mode('black', 'white')(props),
        fontSize: ['14px', null, null, '24px'],
        lineHeight: ['24px', null, null, '36px'],
        fontWeight: 600,
      }),
      introductoryBody: {
        fontSize: ['14px', null, null, '24px'],
        lineHeight: ['24px', null, null, '36px'],
        fontWeight: 400,
      },
      autherHeaderDescription: (props: StyleFunctionProps) => ({
        fontSize: [12, null, null, 16],
        color: 'textRegular',
        lineHeight: '24px',
        textAlign: 'center',
      }),
      detailsLateralHeading: {
        mt: '1rem',
        fontWeight: 600,
        fontSize: 16,
      },
      detailsLateralSubHeading: {
        mt: [6, null, null, 4],
        color: 'textRegular',
        fontSize: 16,
      },
      detailsLateralContent: (props: StyleFunctionProps) => ({
        color: mode('textBlack', 'textSoft')(props),
        pt: 1,
        fontSize: [18, null, null, 16],
      }),
      searchHeading: {
        color: 'textRegular',
        fontWeight: [600, null, null, 500],
        lineHeight: '24px',
        fontSize: 16,
      },
      searchSubHeading: {
        fontWeight: 500,
        lineHeight: '32px',
        fontSize: '20px',
      },
      subtitlePath: {
        fontWeight: 600,
        lineHeight: '16px',
        fontSize: 12,
      },
    },
  },
  Tooltip: defineStyleConfig({
    baseStyle: (props: StyleFunctionProps) => ({
      fontFamily: 'Inter',
      bg: 'transparent',
      color: mode('textBlack', 'textSoft')(props),
      fontSize: [9, null, null, 12],
    }),
    sizes: {
      sm: defineStyle({
        fontSize: [9, null, null, 12],
      }),
      md: defineStyle({
        fontSize: [12, null, null, 14],
      }),
      lg: defineStyle({
        fontSize: [14, null, null, 16],
      }),
    },
  }),
  Select: {
    defaultProps: {
      focusBorderColor: 'tipbox.primary.500',
    },
    variants: {
      admin: () => ({
        icon: {
          color: 'textSoft',
        },

        field: {
          bg: 'background',
          color: 'textHighlight',
          textOverflow: 'ellipsis',
          _selection: {
            backgroundColor: 'tipbox.dark.background.charcoalAccent',
          },
          _focusVisible: {
            outline: '1px solid var(--chakra-colors-borderFocus)',
          },
        },
      }),
    },
  },
  NumberInput: {
    defaultProps: {
      focusBorderColor: 'tipbox.primary.500',
    },
  },
  Input: {
    baseStyle: ({ colorMode }: StyleFunctionProps) => ({
      field: {
        bg: 'textHighlight',
        color: 'textBlack',
        _placeholder: { color: 'textBlack' },
      },
    }),
    defaultProps: {
      focusBorderColor: 'accent',
      borderRadius: 8,
    },
    sizes: {
      md: {
        field: {
          borderRadius: 4,
          bg: 'black',
        },
        addon: {
          bg: 'black',
        },
      },
    },
    variants: {
      outline: (props: StyleFunctionProps) => ({
        field: {
          bg: 'textHighlight',
          textOverflow: 'ellipsis',
          type: 'text',
          _hover: { borderColor: 'textBlack' },
          _autofill: {
            border: '1px solid var(--chakra-colors-textBlack)',
            textFillColor: 'var(--chakra-colors-textBlack)',
            boxShadow: '0 0 0px 1000px var(--chakra-colors-textHighlight) inset',
          },
          _selection: {
            backgroundColor: 'tipbox.dark.background.charcoal',
            color: 'tipbox.dark.text.pearl',
          },
        },
        fileBox: {
          w: 'full',
          border: '1px',
          borderStyle: 'dashed',
          borderColor: 'borderRegular',
          p: 2,
          rounded: 8,
          mt: 3,
        },
      }),
      signup: (props: StyleFunctionProps) => ({
        field: {
          bg: 'textHighlight',
          textOverflow: 'ellipsis',
          type: 'text',
          fill: 'tipbox.dark.text.darkGrey',
          _hover: { borderColor: 'accentIcon' },
          _autofill: {
            border: '1px solid var(--chakra-colors-textBlack)',
            textFillColor: 'var(--chakra-colors-textBlack)',
            boxShadow: '0 0 0px 1000px var(--chakra-colors-textHighlight) inset',
          },
          _selection: {
            backgroundColor: 'tipbox.dark.background.charcoal',
            color: 'tipbox.dark.text.pearl',
          },
          _focusVisible: {
            border: '1px solid borderFocus',
            outline: '1px solid #02F7B6',
          },
          _focus: {
            border: '1px solid borderFocus',
            outline: '1px solid #02F7B6',
          },
          _placeholder: {
            color: 'tipbox.dark.text.darkGrey',
          },
        },
        fileBox: {
          w: 'full',
          border: '1px',
          borderStyle: 'dashed',
          borderColor: 'borderRegular',
          p: 2,
          rounded: 8,
          mt: 3,
        },
      }),
      admin: () => ({
        field: {
          w: '27rem',
          bg: 'background',
          color: 'textHighlight',
          textOverflow: 'ellipsis',
          _selection: {
            backgroundColor: 'tipbox.dark.background.charcoalAccent',
          },
          _focusVisible: {
            outline: '1px solid var(--chakra-colors-borderFocus)',
          },
        },
      }),
      modal: (props: StyleFunctionProps) => ({
        field: {
          border: '1px',
          fontSize: '13px',
          borderColor: 'tipbox.dark.border.light',
          backgroundColor: 'textHighlight',
          color: 'tipbox.dark.text.greyMedium',
          borderRadius: 8,
          _disabled: {
            border: '1px',
            fontSize: '13px',
            borderColor: 'tipbox.dark.border.light',
            background: 'textHighlight',
            cursor: 'default',
            color: 'tipbox.dark.text.greyMedium',
            opacity: 1,
          },
          _focusVisible: {
            borderColor: '#000000',
            border: '2px',
          },
        },
      }),
      ghost: (props: StyleFunctionProps) => ({
        field: {
          bg: mode('white', 'transparent')(props),
          textOverflow: 'ellipsis',
          type: 'text',
          fontWeight: 400,
          fontSize: 16,
          border: 'none',
          _focus: { boxShadow: 'none' },
          _disabled: {
            color: mode('textBlack', 'textSoft')(props),
          },
        },
        fileBox: {
          w: 'full',
          border: '1px',
          borderStyle: 'dashed',
          borderColor: 'borderLight',
          p: 2,
          rounded: 8,
          mt: 3,
        },
      }),
      tipTitle: (props: StyleFunctionProps) => ({
        field: {
          bg: 'transparent',
          textOverflow: 'ellipsis',
          type: 'text',
          fontWeight: 600,
          fontSize: 40,
          pt: 6,
          pb: 6,
          px: 0,
          mb: 10,
          borderRadius: 0,
          borderColor: mode('borderLight', 'borderRegular')(props),
          _focus: { boxShadow: 'none' },
          _disabled: {
            // color: mode('textBlack', 'textSoft')(props),
            color: '#fff',
            opacity: '1',
          },
          _placeholder: {
            color: mode('textBlack', 'textSoft')(props),
          },
        },
        fileBox: {
          w: 'full',
          border: '1px',
          borderStyle: 'dashed',
          borderColor: 'borderLight',
          p: 2,
          rounded: 8,
          mt: 3,
        },
      }),
      tipSaveModalTitle: (props: StyleFunctionProps) => ({
        field: {
          bg: 'transparent',
          textOverflow: 'ellipsis',
          type: 'text',
          fontWeight: 600,
          fontSize: 14,
          // pt: 6,
          // pb: 6,
          my: 0,
          px: 0,
          // mb: 10,
          borderRadius: 0,
          // borderColor: mode('borderLight', 'tipbox.dark.700')(props),
          _focus: { boxShadow: 'none' },
          _disabled: {
            color: 'textRegular',
          },
          _placeholder: {
            color: 'textRegular',
          },
        },
        fileBox: {
          w: 'full',
          border: '1px',
          borderStyle: 'dashed',
          borderColor: 'borderRegular',
          p: 2,
          rounded: 8,
          mt: 3,
        },
      }),
    },
  },
  Link: {
    baseStyle: (props: StyleFunctionProps) => ({
      fontWeight: 600,
      fontFamily: 'Inter',
      color: 'accentIcon',
      fontSize: [14, null, null, 16],
    }),
    variants: {
      regularText: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        fontWeight: 400,
        color: mode('textBlack', 'textSoft')(props),
        fontSize: [14, null, null, 16],
      }),
      highlightText: (props: StyleFunctionProps) => ({
        fontFamily: 'Inter',
        color: mode('black', 'white')(props),
        fontSize: [14, null, null, 16],
      }),
      inlineText: {
        w: 'auto',
        fontWeight: 500,
        pl: 1,
        pr: 1,
        pb: 1,
      },
      introductoryTitle: {
        fontSize: ['18px', null, null, '48px'],
        lineHeight: ['24px', null, null, '50px'],
        fontWeight: 400,
      },
      introductoryBody: {
        fontSize: ['14px', null, null, '24px'],
        lineHeight: ['24px', null, null, '36px'],
        fontWeight: 400,
      },
    },
  },
  Section: {
    baseStyle: {
      bg: 'tipbox.backgroundGray',
    },
    variants: {},
  },
  Badge: {
    baseStyle: {
      bg: 'tipbox.backgroundGray',
      fontSize: 'lg',
    },
    variants: {},
  },
  Popover: {
    baseStyle: (props: StyleFunctionProps) => ({
      popper: {
        width: 'fit-content',
        minW: 'fit-content',
        zIndex: 100,
      },
      content: {
        background: mode('white', 'black')(props),
      },
    }),
    variants: {
      autoWidth: {
        popper: {
          width: 'fit-content',
          minW: 'fit-content',
          p: 0,
          boxShadow: '0px 2px 10px rgba(26, 26, 26, 0.15)',
          borderRadius: 8,
        },
        content: {
          borderRadius: 8,
          w: 'fit-content',
          p: 0,
          border: 0,
        },
      },
      dashboardPopover: {
        popper: {
          width: 'fit-content',
          minW: 'fit-content',
          bg: 'textHighlight',
          boxShadow: '0px 2px 10px rgba(26, 26, 26, 0.15)',
          borderRadius: 8,
        },
        header: {
          bg: 'textHighlight',
        },
        body: {
          bg: 'textHighlight',
          p: 0,
        },
        content: {
          w: 'fit-content',
          maxH: '55vh',
          borderRadius: 8,
          p: 0,
          border: 0,
        },
        closeButton: {
          color: 'textBlack',
        },
      },
      popoverMenu: {
        popper: {
          width: ['fit-content'],
          minW: 'fit-content',
          p: 0,
          boxShadow: '0px 2px 10px rgba(26, 26, 26, 0.15)',
          borderRadius: 8,
          m: 0,
          _focus: { border: 'none' },
        },
        content: {
          borderRadius: 8,
          width: 'fit-content',
          p: 0,
          m: 0,
          border: 0,
        },
      },
      popoverListCardMenu: {
        popper: {
          width: 'fit-content',
          minW: 'fit-content',
          p: 0,
          boxShadow: '0px 2px 10px rgba(26, 26, 26, 0.15)',
          borderRadius: 8,
          _focus: { border: 'none' },
        },
        content: {
          borderRadius: 8,
          width: 'fit-content',
          p: 0,
          border: 0,
        },
      },
    },
  },
  ListItem: {
    baseStyle: (props: StyleFunctionProps) => ({
      color: mode('tipbox.primary.600', 'accent')(props),
    }),
    variants: {
      errorList: (props: StyleFunctionProps) => ({
        color: mode('tipbox.primary.600', 'accent')(props),
      }),
    },
  },
  Tag: {
    baseStyle: (props: StyleFunctionProps) => ({
      container: {
        bg: 'accent',
      },
      label: {
        lineHeight: 1.2,
        overflow: 'visible',
        color: 'white',
      },
    }),
    variants: {
      greyBackground: {
        container: {
          bg: 'cardBackground',
        },
      },
    },
  },
  Table: {
    parts: ['th', 'td', 'tr'],
    baseStyle: (props: StyleFunctionProps) => ({
      root: {
        borderSpacing: '0 8px',
      },
      th: {
        // padding: '0 !important'
        textTransform: 'none',
        fontWeight: 500,
        fontSize: '12px',
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
      },
      tr: {
        fontSize: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      td: {
        textAlign: 'center',
        fontSize: '12px',
        color: 'tipbox.dark.text.black',
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
      },
    }),
  },
  Tabs: {
    parts: ['root', 'tablist', 'tab'],
    baseStyle: (props: StyleFunctionProps) => ({
      root: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 16,
        color: 'textRegular',
        borderColor: mode('borderLight', 'borderRegular')(props),
      },
      tablist: {
        bg: [mode('textSoft', 'tipbox.dark.900')(props), null, null, mode('white', 'black')(props)],
      },
      tab: {
        fontWeight: 600,
        fontSize: 16,
        _focus: { boxShadow: 'none' },
        _selected: {
          color: 'textHighlight',
        },
        _hover: {
          color: mode('tipbox.primary.600', 'white')(props),
        },
      },
    }),
    variants: {
      mainScene: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          color: 'textRegular',
          // w: 'full',
          // minW: '100%',
          m: 0,
          // flex: 1,
          px: [4, null, null, null, 0],
        },
        tablist: {
          borderColor: mode('borderLight', 'transparent')(props),
          bg: mode('textSoft', 'tipbox.dark.900')(props),
          m: 0,
          p: 0,
        },
        tab: {
          fontSize: 14,
          fontWeight: 600,
          px: 0,
          mr: [4, null, 8],
          _focus: { boxShadow: 'none' },
          _selected: {
            fontWeight: '600',
            borderBottom: '2px',
            borderColor: mode('tipbox.primary.600', 'white')(props),
            color: mode('tipbox.primary.600', 'white')(props),
          },
        },
      }),
      dashboardTabs: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: 16,
          color: 'textRegular',
          borderColor: mode('borderLight', 'borderRegular')(props),
          borderRadius: 8,
          overflow: 'auto',
          bg: 'textHighlight',
        },
        tablist: {},
        tab: {
          fontWeight: 600,
          fontSize: 16,
          _focus: { boxShadow: 'none' },
          _selected: {
            color: 'textHighlight',
          },
          _hover: {
            color: mode('tipbox.primary.600', 'white')(props),
          },
        },
      }),
      searchSuggestion: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: 16,
          color: 'textRegular',
          borderColor: mode('borderLight', 'borderRegular')(props),
        },
        tablist: {
          bg: mode('white', 'black')(props),
          m: 0,
          p: 0,
        },
        tab: {
          fontWeight: 600,
          fontSize: [12, null, 14, null, 16],
          px: 0,
          mr: [4, null, 8],
          _focus: { boxShadow: 'none' },
          _hover: {
            borderBottom: '2px',
            borderColor: mode('tipbox.primary.600', 'white')(props),
            color: mode('tipbox.primary.600', 'white')(props),
          },
          _selected: {
            fontWeight: '600',
            borderBottom: '2px',
            borderColor: mode('tipbox.primary.600', 'accent')(props),
            color: mode('tipbox.primary.600', 'white')(props),
          },
        },
      }),
      tipTabs: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          color: 'textRegular',
          m: 0,
          px: [4, null, null, null, 0],
        },
        tablist: {
          bg: [mode('textSoft', 'tipbox.dark.900')(props), null, null, mode('white', 'background')(props)],
        },
      }),
      documentTabs: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          color: 'textRegular',
          fontWeight: 500,
          m: 0,
          px: [4, null, null, null, 0],
        },
        tablist: {
          bg: 'transparent',
          mt: 4,
        },
        tabpanel: {
          py: 0,
        },
        tab: {
          fontWeight: 500,
          fontSize: [12, null, 14, null, 16],
          px: 4,
          color: 'textRegular',
          height: '35px',
          my: 'auto',
          borderRadius: '20',
          borderColor: 'transparent',
          _focus: { boxShadow: 'none' },
          _selected: {
            color: 'textHighlight',
          },
        },
      }),
      flowTabs: (props: StyleFunctionProps) => ({
        root: {
          fontFamily: 'Inter',
          color: 'textRegular',
          m: 0,
          px: [4, null, null, null, 0],
        },
        tablist: {
          bg: 'transparent',
          mt: 4,
        },
        tabpanel: {
          py: 0,
        },
        tab: {
          fontWeight: 500,
          fontSize: [12, null, 14, null, 16],
          px: 4,
          color: 'textRegular',
          height: '35px',
          my: 'auto',
          borderRadius: '20',
          borderColor: 'transparent',
          _focus: { boxShadow: 'none' },
          _hover: {
            borderColor: 'transparent',
            // bg: mode('white', 'black')(props),
            border: 'none',
          },
          _selected: {
            color: 'textHighlight',
          },
        },
      }),
      projectTabs: (props: StyleFunctionProps) => ({
        tablist: {
          borderBottom: '1px',
          borderColor: mode('textSoft', 'textSoft')(props),
          bg: [mode('textSoft', 'tipbox.dark.900')(props), null, null, mode('textSoft', 'tipbox.dark.900')(props)],
        },
        tab: {
          color: 'textSoft',
          borderColor: 'textSoft',
          border: 1,
          _selected: {
            color: 'textHighlight',
            border: 0,
          },
          _hover: {
            color: 'textHighlight',
            border: 0,
          },
        },
      }),
    },
  },
  Accordion: {
    variants: {
      fileTree: {
        button: {
          border: '0px',
          fontFamily: 'Inter',
        },
      },
    },
  },
  Progress: {
    baseStyle: {
      filledTrack: {
        bg: 'accent',
      },
    },
  },
  Radio: {
    baseStyle: {
      control: {
        border: '1px',
        _checked: {
          bg: 'accent',
          borderColor: 'accent',
          color: 'white',
          _hover: {
            bg: 'accentHighlight',
            borderColor: 'accentHighlight',
          },
        },
      },
    },
  },
}

export default components
